import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerAnalytics from '../images/layerAnalytics.svg';
import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerRealtime from '../images/layerRealtime.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import LibertyRing from '../animations/libertyRing.json';

import Default from '../../components/Layouts/Default';

var lottie;

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.animationIsAttached = false;
  }

  componentDidMount() {
    lottie = require('lottie-web');
    this.attachAnimation();
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing
      };

      lottie.loadAnimation(animationProperties);
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Platform</title>
          <meta
            name="description"
            content="Accessible, Useful, and Safe. These are the benefits of adopting an all-in-one voice platform."
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                One Vertically Integrated Platform
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Save Time, Save Money, Deliver Better Experiences
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 1]} mb={['32px', 0]}>
              <CenteredImg src={ConversationCloud} alt="Conversation Cloud"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <Title3 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                WHY ALL IN ONE?
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <b>Accessible:</b> With a single voice solution in the cloud, you can capture every customer
                conversation at a fraction of the cost, and without any changes to rep behavior.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <b>Useful:</b> With a larger dataset and complete visibility into the actual phone conversation, you can
                identify and capitalize upon opportunities in realtime.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <b>Safe:</b> Passing your customers' data from vendor to vendor leaves your company vulnerable and
                liable. By consolidating multiple solutions into one platform, you can keep customer data safe.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <Values>
          <Section flexDirection="column">
            <Title2 color={colors.accentFog} mt="32pt">
              PLATFORM OVERVIEW
            </Title2>
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerConnector} alt="Connector"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Apps For Every Device
              </PageTitle3>
              <Large center color={colors.white}>
                Apps supported across all major platforms: Mac, Windows, Chrome OS, Web, Deskphones, Conference Room Phones, Web Meetings (coming soon)
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Enterprise Grade PBX
              </PageTitle3>
              <Large center color={colors.white}>
                Our apps connect to an enterprise grade, SIP-compatible, phone system platform complete with CallCenter/IVR, Extensions, and more.  
                <br/><br/>
                Running on a cloud-native, fully redundant and scalable architecture, you get industry-leading uptime and quality.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pl={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerTelephony} alt="Telephony"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerGlobal} alt="Global"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Global Carrier Network
              </PageTitle3>
              <Large center color={colors.white}>
                Experience Truly's global voice network. Our highly curated, strategic partnerships with Tier-1 carriers
                provide a highly differentiated level of call quality everywhere in the world.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                App Agnostic Workflow
              </PageTitle3>
              <Large center color={colors.white}>
                No matter what solutions you use (CRM, Sales Engagement, Helpdesk, ATS, Conversation Intelligence, Business Analytics), Truly offers the most advanced integration options in the market.  
                <br/><br/>
                This means that your phone system isn't tied to your tech stack, and your business can evolve more quickly without worrying about the complexity of migrating voice providers each time.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerWorkflow} alt="Workflow"/>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']}>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pb={['32pt', '0pt']}>
              <WidthImg src={LayerRealtime} alt="Realtime"/>
            </Box>
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                AI & Transcription
              </PageTitle3>
              <Large center color={colors.white}>
                Keep complete command and control of your voice data.  Leverage our native conversation intelligence capabilities for maximum security/compliance, or send your data to one of many voice analytics providers like Gong.io, Chorus.ai, Execvision, Amazon Web Services, and more.
              </Large>
            </Box>
          </Section>
          <Section flexDirection="column">
            <Divider />
          </Section>
          <Section flexDirection={['column', 'row']} pb="64pt">
            <Box width={[1, 2 / 3]}>
              <PageTitle3 pb="8pt" color={colors.white} center>
                Analytics Layer
              </PageTitle3>
              <Large center color={colors.white}>
                Truly’s native business intelligence platform delivers flexible data visualizations and rich insights.
              </Large>
            </Box>
            <Box width={[1 / 3, 1 / 4]} pr={['0pt', '32pt']} pt={['32pt', '0pt']}>
              <WidthImg src={LayerAnalytics} alt="Analytics"/>
            </Box>
          </Section>
        </Values>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
